import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useParams } from "react-router-dom";
import Button from "components/core/Button";
import { Subtitle } from "components/core/Typography";
import btnPlayStore from "assets/images/google-play-badge.png";
import btnAppleStore from "assets/images/apple-store-badge.png";

const OpenAppContainer = () => {
  const currentUrl = window.location.href;
  const isProduction = /admin.obviacontabil.com/.test(currentUrl);
  const { page } = useParams();
  const prefix = `obviaapp${isProduction ? "" : "dev"}://`;
  const pages = {
    financeiro: "financial",
    login: "login",
  };
  const isMobile = navigator.userAgent.match(/(iPad)|(iPhone)|(iPod)|(android)|(webOS)/i);
  const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
  const isAndroid = /android/i.test(navigator.userAgent);
  const url = `${prefix}${pages[page]}`;
  const openApp = () => {
    if (isMobile) {
      if (isIOS) {
      }
      if (isAndroid) {
        window.location.href = url;
      }
    }
  };
  useEffect(() => {
    if (isMobile) {
      window.location.href = url;
    }
  }, [isMobile]);

  const goTo = (store) => {
    if (store === "apple") {
      window.location.href = "https://apps.apple.com/br/app/obvia-contabil/id1531001633";
    }
    if (store === "google") {
      window.location.href = "https://play.google.com/store/apps/details?id=com.obvia";
    }
  };

  return (
    <Container>
      <>
        {isMobile && <Button handleClick={openApp}>Abrir aplicativo</Button>}
        <Subtitle style={{ textAlign: "center", marginTop: "3.4rem", marginRight: "2.4rem" }}>
          Se você ainda não possui o aplicativo da OBVIA, clique no botão abaixo para baixar o App.
        </Subtitle>
        <Image src={btnPlayStore} alt={"Baixe na Google Play"} onClick={() => goTo("google")} />
        <Image src={btnAppleStore} alt={"Baixe na App Store"} onClick={() => goTo("apple")} />
      </>
    </Container>
  );
};

const Container = styled.div`
  width: "100%";
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Image = styled.img`
  width: 300px;
  cursor: pointer;
  margin-top: 3rem;
  margin-right: 2.4rem;
`;

export default OpenAppContainer;
